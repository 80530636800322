import { z } from "zod";

export const marketingCampaignSchema = z.object({
  source: z.string().optional(),
  medium: z.string().optional(),
  campaign: z.string().optional(),
  term: z.string().optional(),
});

export type MarketingCampaign = z.infer<typeof marketingCampaignSchema>;
