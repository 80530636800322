import { AssetType, CaseType, LoanType } from "@prisma/client";
import { type QuestionOption } from "~/modules/onboarding/interfaces";
import { AssetTypeDisplay } from "~/static/assets";
import { ChargeType } from "../../enums";

export const booleanOptions: QuestionOption[] = [
  {
    label: "Oui",
    value: "true",
  },
  {
    label: "Non",
    value: "false",
  },
];

export const assetTypeOptions: QuestionOption[] = [
  {
    label: AssetTypeDisplay[AssetType.residential_property],
    value: AssetType.residential_property,
  },
  {
    label: AssetTypeDisplay[AssetType.commercial_property],
    value: AssetType.commercial_property,
  },
  {
    label: AssetTypeDisplay[AssetType.industrial_property],
    value: AssetType.industrial_property,
  },
];

export const loanTypeOptions: QuestionOption[] = [
  {
    label: "Amortissable",
    value: LoanType.depreciable,
  },
  {
    label: "In fine",
    value: LoanType.in_fine,
  },
];

export const caseTypeOptions: QuestionOption[] = [
  {
    label: "Société",
    value: CaseType.patrimonial,
  },
  {
    label: "Particulier",
    value: CaseType.standard,
  },
];

export const chargeTypeOptions: QuestionOption[] = [
  {
    label: "Crédit consommation",
    value: ChargeType.consumer,
  },
  {
    label: "Pension alimentaire",
    value: ChargeType.additional,
  },
  {
    label: "Prêt immobilier",
    value: ChargeType.realEstate,
  },
  {
    label: "Loyer",
    value: ChargeType.rent,
  },
];
