import { StoreKeyName } from "~/app/enums";
import {
  type ContactData,
  type DataInLocalStorage,
  type OnboardingType,
} from "~/modules/onboarding/interfaces";
import {
  OnboardingTypeEnum,
  type schemaAnswers,
  schemaAnswersInLocalStorage,
  schemaContactForm,
} from "~/modules/onboarding/schemas";
import {
  type MarketingCampaign,
  marketingCampaignSchema,
} from "~/schema/marketing";
import { schemaPartnerCreation } from "~/schema/partner";

// Retrieve onboarding type
export const getOnboardingTypeFromLocalStorage = (): OnboardingType | null => {
  return (
    getFromLocalStorage<OnboardingType>(StoreKeyName.onboardingType) ?? null
  );
};

// Fetch all client onboardings data
export const getOnboardingData = () => {
  return {
    parsedAnswers: getParsedAnswersFromLocalStorage(),
    parsedContact: getParsedContactFromLocalStorage(),
    marketingCampaign: getParsedMarketingCampaignFromLocalStorage(),
  };
};

// Check if onboarding is a client onboarding type
export const isCustomerCaseType = (type: OnboardingType): boolean => {
  return OnboardingTypeEnum.safeParse(type).success;
};

export const getFromLocalStorage = <T>(key: string): T | undefined => {
  if (typeof window === "undefined") return undefined;

  const storedValue = localStorage.getItem(key);
  if (!storedValue || storedValue === "undefined") return undefined;

  try {
    return JSON.parse(storedValue) as T;
  } catch (error) {
    console.error(
      `Warning: Could not parse localStorage data for key "${key}".`,
      error,
    );
    return undefined;
  }
};

// Get and parse answers from local storage
export const getParsedAnswersFromLocalStorage = ():
  | DataInLocalStorage<typeof schemaAnswers>
  | undefined => {
  const answersFromLocalStorage = localStorage.getItem(StoreKeyName.answers);
  if (!answersFromLocalStorage) return undefined;

  const result = schemaAnswersInLocalStorage.safeParse(
    JSON.parse(answersFromLocalStorage),
  );

  if (!result.success) {
    console.error(`Failed to parse localStorage answers":`, result.error);
    return undefined;
  }
  return result.data;
};

// Get and parse marketing campaign from local storage
export const getParsedMarketingCampaignFromLocalStorage = ():
  | MarketingCampaign
  | undefined => {
  const marketingCampaignStorage = localStorage.getItem(StoreKeyName.marketing);
  if (!marketingCampaignStorage) return undefined;

  const result = marketingCampaignSchema.safeParse(
    JSON.parse(marketingCampaignStorage),
  );

  if (!result.success) {
    console.error(
      `Failed to parse localStorage marketing campaign":`,
      result.error,
    );
    return undefined;
  }
  return result.data;
};

// Get and parse contact from local storage
export const getParsedContactFromLocalStorage = (): ContactData | undefined => {
  const contactStorage = localStorage.getItem(StoreKeyName.contact);
  if (!contactStorage) return undefined;

  const result = schemaContactForm.safeParse(JSON.parse(contactStorage));

  if (!result.success) {
    console.error(`Failed to parse localStorage contact":`, result.error);
    return undefined;
  }
  return result.data;
};

// Get and parse partner from local storage
export const getParsedPartnerFromLocalStorage = () => {
  const partnerStorage = localStorage.getItem(StoreKeyName.partners);
  if (!partnerStorage) return undefined;

  const result = schemaPartnerCreation.safeParse(JSON.parse(partnerStorage));

  if (!result.success) {
    console.error(`Failed to parse localStorage partner":`, result.error);
    return undefined;
  }
  return result.data;
};
