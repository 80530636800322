import { branchesPatrimonial } from "~/modules/onboarding/static/patrimonial/branches";
import { categoriesPatrimonial } from "~/modules/onboarding/static/patrimonial/categories";
import { branchesSenior } from "~/modules/onboarding/static/senior/branches";
import { categoriesSenior } from "~/modules/onboarding/static/senior/categories";
import { branches } from "~/modules/onboarding/static/standard/branches";
import { categories } from "~/modules/onboarding/static/standard/categories";
import {
  type CaseOnboardingType,
  type OnboardingStructure,
  type OnboardingType,
} from "../interfaces";
import { OnboardingTypeEnum } from "../schemas";
import { branchesObo } from "../static/obo/branches";
import { categoriesObo } from "../static/obo/categories";
import { questionsObo } from "../static/obo/questions";
import { questionsPatrimonial } from "../static/patrimonial/questions";
import { questionsSenior } from "../static/senior/questions";
import { questions } from "../static/standard/questions";

const onboardingTypeError =
  "Invalid onboarding type: navigation error on onboarding page.";

export const getOnboardingTypeByPath = (
  pathname: string,
): CaseOnboardingType => {
  const { patrimonial, senior, standard, obo } = OnboardingTypeEnum.Values;

  if (pathname.includes(patrimonial)) return patrimonial;
  if (pathname.includes(senior)) return senior;
  if (pathname.includes(standard)) return standard;
  if (pathname.includes(obo)) return obo;

  // TODO: this default value shoud be removed
  // but this requires a refactor useOnboardingAtoms hook
  return standard;
};

export const getOnboardingPathByType = (
  onboardingType: OnboardingType,
): string => {
  const { patrimonial, senior, standard, obo } = OnboardingTypeEnum.Values;

  switch (onboardingType) {
    case standard:
      return "/onboarding/standard";
    case patrimonial:
      return "/onboarding/patrimonial";
    case senior:
      return "/onboarding/senior";
    case obo:
      return "/onboarding/obo";
    default:
      throw new Error(onboardingTypeError);
  }
};

export const getOnboardingStructureByType = (
  onboardingType: string,
): OnboardingStructure => {
  const { patrimonial, senior, standard, obo } = OnboardingTypeEnum.Values;

  switch (onboardingType) {
    case standard:
      return {
        categories: categories,
        branches: branches,
      };
    case patrimonial:
      return {
        categories: categoriesPatrimonial,
        branches: branchesPatrimonial,
      };
    case senior:
      return {
        categories: categoriesSenior,
        branches: branchesSenior,
      };
    case obo:
      return {
        categories: categoriesObo,
        branches: branchesObo,
      };
    default:
      throw new Error(onboardingTypeError);
  }
};

export const getOnboardingQuestionsByType = (
  onboardingType: OnboardingType,
) => {
  const { patrimonial, senior, standard, obo } = OnboardingTypeEnum.Values;

  switch (onboardingType) {
    case standard:
      return questions;
    case patrimonial:
      return questionsPatrimonial;
    case senior:
      return questionsSenior;
    case obo:
      return questionsObo;
    default:
      throw new Error(onboardingTypeError);
  }
};
