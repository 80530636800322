import {
  ProspectAmountSchema,
  ProspectPatrimonySchema,
  ProspectProductSchema,
  ProspectRatingSchema,
  ProspectUrgencySchema,
} from "prisma/schema/generated/zod";
import { z } from "zod";
import {
  schemaContactForm,
  schemaEmail,
  schemaPartnerCompanyForm,
  schemaPartnerProfileForm,
  schemaPhone,
} from "~/modules/onboarding/schemas";

export const schemaPartnerCreation = z.object({
  profile: schemaPartnerProfileForm,
  company: schemaPartnerCompanyForm,
  contact: schemaContactForm,
});

export type PartnerCreation = z.infer<typeof schemaPartnerCreation>;

export const partnerPendingCaseCreationSchema = z.object({
  firstname: z.string().max(68, "Votre prénom est trop long"),
  lastname: z.string().max(68, "Votre nom est trop long"),
  email: schemaEmail,
  phone: schemaPhone,
  patrimony: ProspectPatrimonySchema,
  product: ProspectProductSchema,
  amount: ProspectAmountSchema,
  urgency: ProspectUrgencySchema,
  rating: ProspectRatingSchema,
  description: z.string().max(2000, "La description est trop longue"),
});

export type PartnerPendingCaseCreate = z.infer<
  typeof partnerPendingCaseCreationSchema
>;
