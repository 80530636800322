export enum StoreKeyName {
  answers = "onboardingAnswers",
  navigation = "onboardingNavigation",
  contact = "onboardingContact",
  onboardingType = "onboardingType",
  submitPendingCase = "submitPendingCase",
  newClientForm = "newClientForm",
  pendingCaseForm = "pendingCaseForm",
  marketing = "marketing",
  partners = "onboardingPartners",
  affiliateId = "affiliateId",
}
