import { PartnerLoanVolume, PartnerProfession } from "@prisma/client";
import { atomWithStorage } from "jotai/utils";
import {
  PartnersOnboardingStepSlug,
  SimpleOnboardingStepSlug,
} from "~/modules/onboarding/enums";
import type {
  Answer,
  ContactData,
  DataInLocalStorage,
  Navigation,
  OnboardingType,
  PartnerCompany,
  PartnerProfile,
} from "~/modules/onboarding/interfaces";
import { schemaAnswers, schemaNavigation } from "~/modules/onboarding/schemas";
import { getFromLocalStorage } from "~/modules/onboarding/utils/localStorageManager";
import { type MarketingCampaign } from "~/schema/marketing";
import { type SubmitPendingCasePartial } from "~/schema/pendingCase";
import { StoreKeyName } from "../enums";

export const initialAnswers: Answer[] = [];

export const initialNavigation: Navigation = {
  branchSlug: "case-type",
  branchIteration: 1,
  categorySlug: "case",
  categoryIteration: 1,
};

const defaultAnswers: DataInLocalStorage<typeof schemaAnswers> = {
  standard: schemaAnswers.parse(initialAnswers),
  patrimonial: schemaAnswers.parse(initialAnswers),
  senior: schemaAnswers.parse(initialAnswers),
  obo: schemaAnswers.parse(initialAnswers),
};

export const defaultNavigation: DataInLocalStorage<typeof schemaNavigation> = {
  standard: schemaNavigation.parse(initialNavigation),
  patrimonial: schemaNavigation.parse(initialNavigation),
  senior: schemaNavigation.parse(initialNavigation),
  obo: schemaNavigation.parse({
    ...initialNavigation,
    categorySlug: "real-estate-patrimony",
    branchSlug: "asset-creation-start",
  }),
};

export const defaultContact: ContactData = {
  firstName: "",
  lastName: "",
  phone: "",
};

export const answersAtom = atomWithStorage<typeof defaultAnswers>(
  StoreKeyName.answers,
  defaultAnswers,
);

export const navigationAtom = atomWithStorage<typeof defaultNavigation>(
  StoreKeyName.navigation,
  defaultNavigation,
);

export const contactAtom = atomWithStorage<ContactData>(
  StoreKeyName.contact,
  defaultContact,
);

export const affiliateIdAtom = atomWithStorage<string | undefined>(
  StoreKeyName.affiliateId,
  getFromLocalStorage<string | undefined>(StoreKeyName.affiliateId),
);

export const onboardingTypeAtom = atomWithStorage<OnboardingType>(
  StoreKeyName.onboardingType,
  "standard",
);

export const marketingAtom = atomWithStorage<MarketingCampaign | undefined>(
  StoreKeyName.marketing,
  undefined,
);

type SubmitPendingCaseAtom = {
  pendingCase: SubmitPendingCasePartial | undefined;
  filesKeys: string[];
  navigation: SimpleOnboardingStepSlug;
};

export const submitPendingCaseAtom = atomWithStorage<
  SubmitPendingCaseAtom | undefined
>(StoreKeyName.submitPendingCase, {
  pendingCase: getFromLocalStorage<SubmitPendingCaseAtom>(
    StoreKeyName.submitPendingCase,
  )?.pendingCase,
  filesKeys: [],
  navigation: SimpleOnboardingStepSlug.Project,
});

type OnboardingPartnersAtom = {
  navigation: PartnersOnboardingStepSlug;
  profile?: PartnerProfile;
  company?: PartnerCompany;
};

export const defaultPartnerProfile = {
  profession: PartnerProfession.lawyer,
  otherProfession: undefined,
  loanVolume: PartnerLoanVolume.below_1M,
  clientTypes: [],
  usageCases: [],
};

const initialValuesOnboardingPartnersAtom: OnboardingPartnersAtom = {
  navigation: PartnersOnboardingStepSlug.Profile,
  profile: undefined,
  company: undefined,
};

export const onboardingPartnersAtom = atomWithStorage<
  OnboardingPartnersAtom | undefined
>(StoreKeyName.partners, initialValuesOnboardingPartnersAtom);
